<template>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <h3 class="text-center">Einweisung speichern</h3>

                <div class="row pt-5">
                    <div class="col"><h5><i class="bi bi-clock-history"></i> Startzeit:</h5></div>
                    <div class="col"><h5>{{ startTime }} Uhr</h5></div>
                </div>
                <div class="row pt-2">
                    <div class="col"><h5><i class="bi bi-people-fill"></i> Teilnehmer:</h5></div>
                    <div class="col"><h5>{{ attendees }}</h5></div>
                </div>
                <div class="row pt-2">
                    <div class="col"><h5><i class="bi bi-ladder"></i> Parcours:</h5></div>
                    <div class="col"><h5>{{ parcoursID }}</h5></div>
                </div>
                <form @submit.prevent="onFormSubmit" @reset="onFormReset">

                    <div class="row pt-2 pb-4">
                        <div class="col"><h5><i class="bi bi-person-bounding-box"></i> Trainer:</h5></div>
                        <div class="col">
                            <select class="custom-select custom-select-lg mb-3 form-control" v-model="briefing.Trainer" required>
                                <option></option>
                                <option v-for="trainer in trainers" :key="trainer.trainerName" :value="trainer.trainerName" >{{ trainer.trainerName }}</option>
                            </select>
                        </div>
                    </div>

                <div class="form-group">
                    <div class="row pt-2">
                        <div class="col-6 pr-0">
                            <button class="btn btn-danger btn-block btn-lg" type="reset" value="Reset"><i class="bi bi-x-circle"></i> Abbrechen</button>
                        </div>
                        <div class="col-6 pl-1">
                            <button class="btn btn-primary btn-block btn-lg"><i class="bi bi-bookmark-plus"></i> Speichern</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
 import { db, Timestamp } from '../firebaseDb'
 import router from '../router'
 import moment from 'moment-timezone'
 import store from "../store"
 import '../assets/bootstrap-icons-1.2.2/font/bootstrap-icons.css'

export default {
  data () {
    return {
        parcoursID: 0,
        attendees: 0,
        startDateTime: new Date(),
        startTime: moment(this.startDateTime).format('HH:mm'),
        trainers: [],
        briefing: {
        },
        timeOffset: null
    }
  },
  created () {
      this.attendees = this.$route.params.attendees
      this.parcoursID = this.$route.params.parcours
      this.parcDocID = this.$route.params.docID
      this.briefing.Trainer = this.$route.params.trainer
      this.parcBlockDuration = this.$route.params.blockDuration
      this.briefingID = this.$route.params.briefingID

      if (localStorage.timeOffset) {
        this.timeOffset = localStorage.timeOffset
      } else {
        this.timeOffset = 0
      }

      // get trainer
      const trainerCollection = db.collection('trainer')
      trainerCollection.where('uid', '==', store.getters.user.data.uid).orderBy('trainerName').onSnapshot((snapshotChange) => {
        this.trainers = []
        snapshotChange.forEach((doc) => {
            this.trainers.push({
                key: doc.id,
                trainerName: doc.data().trainerName,
                trainerStart: doc.data().start,
                trainerEnd: doc.data().end
                })
        })
        })
  },
  methods: {
            onFormSubmit (event) {
                event.preventDefault()
                const safetyBriefingsRef = db.collection('safetyBriefings')

                safetyBriefingsRef.doc(this.briefingID).get().then((doc) => {
                    if (doc.exists) {
                        // do nothing, it is already saved
                        console.log('Einweisung already saved.')
                        router.push({ name: 'dashboard', params: { success: 1 } })
                    } else {
                        safetyBriefingsRef.doc(this.briefingID).set({
                                            Attendees: parseInt(this.attendees),
                                            Parcours: this.parcoursID,
                                            StartTime: Timestamp.fromDate(new Date(this.startDateTime)),
                                            Trainer: this.briefing.Trainer,
                                            uid: store.getters.user.data.uid
                                        }).then(() => {
                                            this.blockingEnd = Date.now() + parseInt(this.timeOffset) + (parseInt(this.parcBlockDuration) * 60 * 1000)
                                            const parcoursRef = db.collection('parcours').doc(this.parcDocID)
                                            parcoursRef.update({ attendees: '0', isWaiting: false, trainer: '', isBlocked: true, blockedUntil: this.blockingEnd, lastTrainer: this.briefing.Trainer, lastAttendees: parseInt(this.attendees), uid: store.getters.user.data.uid })
                                        }).then(() => {
                                            this.$toast.success("Einweisung wurde erfolgreich gespeichert!")
                                            router.push({ name: 'dashboard', params: { success: 1 } })
                                        }).catch((error) => {
                                            this.$toast.error("Fehler beim Speichern der Einweisung!")
                                            console.log(error)
                                        })
                    }
                })
            },
            onFormReset () {
                this.$toast.warning("Speichern der Einweisung wurde abgebrochen.")
                router.push({ name: 'dashboard', params: { success: 0 } })
            }
        }
}
</script>
